<template>
    <!--Start why-choose-->
    <section class="why-choose pad-tb">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="common-heading">
                        <span>We Are Awesome</span>
                        <h2 class="mb30">Why Choose Us</h2>
                    </div>
                </div>
            </div>
            <div class="row upset">
                <div class="col-lg-3 col-sm-6 mt30" v-for="(item, index) in services" :key="index">
                    <div class="s-block up-hor">
                        <div class="s-card-icon">
                            <img :src="item.icon" :alt="item.title" class="img-fluid" />
                        </div>
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End why-choose-->
</template>
 
<script>
export default {
    data() {
        return {
            services: [
                {
                    icon: "assets/images/icons/research.svg",
                    title: "Research and Analysis",
                    description:
                        "We utilize data-driven insights to drive strategic decisions and solve complex problems.",
                },
                {
                    icon: "assets/images/icons/chat.svg",
                    title: "Negotiation and Power",
                    description:
                        "We secure optimal outcomes through expert, strategic negotiation and meticulous deal-making.",
                },
                {
                    icon: "assets/images/icons/monitor.svg",
                    title: "Creative and Innovative Solutions",
                    description:
                        "We deliver cutting-edge solutions through creative problem-solving and innovative approaches.",
                },
                {
                    icon: "assets/images/icons/trasparency.svg",
                    title: "Transparency and Ease of Work",
                    description:
                        "We uphold transparency and streamline processes to enhance operational efficiency and foster trust.",
                },
            ],
        };
    },
    name: "WhyChoose",
};
</script>
 
<style></style>