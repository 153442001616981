<template>
  <!--Start Pricing-->
  <section class="pricing-block pad-tb">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="common-heading ptag">
            <span>Our Pricing</span>
            <h2>Pricing Table</h2>
            <p class="mb30">Choose from one of our three packages, all of which are priced competitively. We offer
              comprehensive yet cost-effective options. Everyone will find enticing features in our Basic, Professional,
              and Premium packages.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 nxpb-1">
          <div class="niwaxpricingbox shadow">
            <div class="pricingheader im1">
              <h2>Starter Package</h2>
              <p>Affordable, quality development. Perfect for startups and small projects.</p>
            </div>
            <div class="pricingrate">
              <div class="pricevalue">
                <h2><sup>₹</sup> 80 K</h2>
              </div>
              <div class="priceoffer">
                <h2><span>₹2K/hour</span></h2>
              </div>
            </div>
            <div class="pricingoffers">
              <ul class="list-ul ul-check">
                <li>Skilled developers ready to deliver high-quality code </li>
                <li>8 hours/day, 5 days/week - Full-time commitment</li>
                <li>Flexible plans: Choose weekly or monthly billing</li>
                <li>Fast onboarding – start your project immediately</li>
                <li>Ideal for projects with tight timelines and budgets.</li>
              </ul>
            </div>
            <div class="priceodernow">
              <div class="priceoderbtn">
                <a href="#" class="niwax-btn3" @click="selectPackage('Starter', '8000000')" data-bs-toggle="modal"
                  data-bs-target="#modalform">Get Started Now<i class="fas fa-chevron-right fa-icon"></i></a>
              </div>
            </div>
            <div class="pricingfooter mt30">
              <div class="pfdiv1"><a href="tel:+91 707-707-6330">Call Us: +91 707-707-6330</a></div>
              <div class="pfdiv2">
                <RouterLink to="/contact-imobile">Need Support</RouterLink>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 nxpb-2">
          <div class="niwaxpricingbox shadow reco">
            <div class="pricingheader im2">
              <h2 id="propackage">Pro Package</h2>
              <p>Experienced developers for growing businesses. Ideal for scaling quickly.</p>
            </div>
            <div class="pricingrate">
              <div class="pricevalue">
                <h2><sup>₹</sup> 1 L</h2>
              </div>
              <div class="priceoffer">
                <h2><span>₹2.5K/hour</span></h2>
              </div>
            </div>
            <div class="pricingoffers">
              <ul class="list-ul ul-check">
                <li>Experienced developers – delivering proven results </li>
                <li>8 hours/day, 5 days/week – Full-time dedication</li>
                <li>Customizable weekly or monthly plans for flexibility</li>
                <li>Quick start – we hit the ground running</li>
                <li>Great for growing businesses with moderate budgets</li>
              </ul>
            </div>
            <div class="priceodernow">
              <div class="priceoderbtn">
                <a href="#" class="niwax-btn2" @click="selectPackage('Pro', '10000000')" data-bs-toggle="modal"
                  data-bs-target="#modalform">Accelerate Your Growth<i class="fas fa-chevron-right fa-icon"></i></a>
              </div>
            </div>
            <div class="pricingfooter mt30">
              <div class="pfdiv1"><a href="tel:+91 707-707-6330">Call Us: +91 707-707-6330</a></div>
              <div class="pfdiv2">
                <RouterLink to="/contact-imobile">Need Support</RouterLink>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 nxpb-3">
          <div class="niwaxpricingbox shadow">
            <div class="pricingheader im3">
              <h2>Expert Package </h2>
              <p>Elite developers for complex, mission-critical projects.</p>
            </div>
            <div class="pricingrate">
              <div class="pricevalue">
                <h2><sup>₹</sup> 1.4 L</h2>
              </div>
              <div class="priceoffer">
                <h2><span>₹3.5k/hour</span></h2>
              </div>
            </div>
            <div class="pricingoffers">
              <ul class="list-ul ul-check">
                <li>Elite developers – with years of hands-on experience </li>
                <li>8 hours/day, 5 days/week – Dedicated to your project</li>
                <li>Premium support and customization available</li>
                <li>Perfect for projects requiring precision and speed</li>
                <li>Tailored weekly or monthly packages for optimal results</li>
              </ul>
            </div>
            <div class="priceodernow">
              <div class="priceoderbtn">
                <a href="#" class="niwax-btn3" @click="selectPackage('Expert', '14000000')" data-bs-toggle="modal"
                  data-bs-target="#modalform">Unlock Top Talent Today
                  Now<i class="fas fa-chevron-right fa-icon"></i></a>
              </div>
            </div>
            <div class="pricingfooter mt30">
              <div class="pfdiv1"><a href="tel:+91 707-707-6330">Call Us: +91 707-707-6330</a></div>
              <div class="pfdiv2">
                <RouterLink to="/contact-imobile">Need Support</RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Pricing-->

  <div class="popup-modals">
    <div class="modal" id="modalform">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="common-heading">
              <h4 class="mt0 mb0">Apply Now</h4>
            </div>
            <button type="button" class="closes" data-bs-dismiss="modal">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body pt40 pb60">
            <div class="form-block fdgn2">
              <!-- <form id="contact-form" method="post" action="#"> -->
              <form @submit.prevent="initiatePayment" id="contact-form" action="#">
                <div class="fieldsets row">
                  <div class="col-md-12 form-group">
                    <input v-model="formData.name" id="name1" type="text" name="name" placeholder="Enter your name *"
                      required="required">
                  </div>
                  <div class="col-md-12 form-group">
                    <input v-model="formData.email" id="email1" type="email" name="email" placeholder="Enter your email *"
                      required="required">
                  </div>
                  <div class="col-md-12 form-group">
                    <!-- <input v-model="formData.phone" type="text" name="phone" placeholder="Enter your Phone No *" required="required">-->
                  </div>
                  <div class="col-md-12 form-group">
                    <!-- <select v-model="formData.plan" name="need" required="required"> -->
                    <!-- <select v-model="formData.plan" name="need" required="required">
                      <option value="">Choose Plan</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </select> -->
                  </div>
                </div>
                <div class="fieldsets mt20">
                  <button type="submit" class="lnk btn-main bg-btn">Proceed to Pay<span class="circle"></span></button>
                </div>
              </form>
              <div v-if="loading" id="loader">Processing...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      formData: {
        rate: '20',
        weekly_amount: '80000',
        monthly_amount: '',
        name: '',
        email: '',
        plan: '',
        planName: '',
      },
      loading: false
    };
  },
  methods: {
    // Method to handle package selection
    selectPackage(planName, amount) {
      this.formData.planName = planName;
      this.formData.monthly_amount = amount; // Set the corresponding amount for the selected package
    },
    async initiatePayment() {
      try {
        this.loading = true;

        // Send form data to Laravel API to initiate payment
        const response = await axios.post('https://razorpay.imobiledesigns.com/api/initiate-payment', this.formData);

        if (response.data.success) {
          // Trigger Razorpay checkout
          const options = {
            key: response.data.key,
            amount: response.data.amount,
            currency: 'INR',
            name: 'Your Service',
            description: this.formData.planName + ' Developer Package',
            order_id: response.data.order_id,
            handler: async (paymentResponse) => {
              // Verify the payment
              try {
                const verificationResponse = await axios.post('https://razorpay.imobiledesigns.com/api/verify-payment', {
                  razorpay_payment_id: paymentResponse.razorpay_payment_id,
                  razorpay_order_id: paymentResponse.razorpay_order_id,
                  razorpay_signature: paymentResponse.razorpay_signature,
                });

                if (verificationResponse.data.success) {
                  alert('Payment Successful!');
                  window.location.reload(); // Reload to show the success message
                } else {
                  alert('Payment Verification Failed!');
                }
              } catch (err) {
                console.error(err);
                alert('Payment Verification Error!');
              } finally {
                this.loading = false;
              }
            },
            prefill: {
              name: this.formData.name,
              email: this.formData.email
            },
            theme: {
              color: '#3399cc',
            },
          };

          const rzp = new Razorpay(options);
          rzp.open();
        } else {
          alert('Error initiating payment. Please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Something went wrong. Please try again.');
      } finally {
        this.loading = false;
      }
    },
  },

  name: "Pricing",
};
</script>

<style scoped>  .im1 {
    background-image: url('../../../public/assets/images/icons/plan-1.svg');
  }

  .im2 {
    background-image: url('../../../public/assets/images/icons/plan-2.svg');
  }

  .im3 {
    background-image: url('../../../public/assets/images/icons/plan-3.svg');
  }
</style>
