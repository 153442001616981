<template>
    <!--Start About-->
    <section class="about-agency pad-tb block-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 v-center">
                    <div class="about-image">
                        <img src="../../../public/assets/images/about/company-about.png" alt="about us" class="img-fluid" />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="common-heading text-l ">
                        <span>{{ aboutUsHeading }}</span>
                        <h2>{{ aboutAgencyHeading }}</h2>
                        <p>{{ agencyDescription1 }}</p>
                        <p>{{ agencyDescription2 }}</p>
                    </div>
                    <div class="row in-stats small about-statistics">
                        <div class="col-lg-4 col-sm-4">
                            <div class="statistics">
                                <div class="statnumb counter-number">
                                    <span class="counter">{{ stats.happyClients }}</span>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <div class="statistics">
                                <div class="statnumb">
                                    <span class="counter">{{ stats.hoursWorked }}</span><span>k</span>
                                    <p>Hours Worked</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <div class="statistics mb0">
                                <div class="statnumb counter-number">
                                    <span class="counter">{{ stats.projectsDone }}</span><span>k</span>
                                    <p>Projects Done</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End About-->
</template>

<script>
export default {
    data() {
        return {
            aboutUsHeading: "About Us",
            aboutAgencyHeading: "About Agency",
            agencyDescription1:
                "Imobile Designs is a premier IT solutions provider with a global reach, committed to delivering transformative technology solutions and services. Established in year 2013, our goal is to empower organizations by integrating advanced technology to foster growth, enhance efficiency, and achieve lasting success.",
            agencyDescription2:
                "At Imobile Designs, we strive to be at the forefront of technological innovation. Our mission is to equip businesses and individuals with the tools and knowledge they need to thrive in a digital world. We focus on creating tailored solutions that address specific challenges, drive operational excellence, and support strategic objectives.",
            stats: {
                happyClients: 350,
                hoursWorked: 200,
                projectsDone: 1.5
            }
        };
    }, name: "About",
};
</script>

<style></style>