<template>
    <!--Start CTA-->
    <section class="cta-area pad-tb">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="common-heading">
                        <span>Let's work together</span>
                        <h2>We Love to Listen to Your Requirements</h2>
                        <RouterLink to="/payment" class="btn-outline">Estimate Project <i
                                class="fas fa-chevron-right fa-icon"></i></RouterLink>
                        <p class="cta-call">Or call us now
                            <a href="tel:+1 707-707-6330"><i class="fas fa-phone-alt"></i>+1
                                707-707-6330 |</a>
                            <a href="tel:+91 707-707-6330">+91 707-707-6330</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape shape-a1"><img src="../../../public/assets/images/shape/shape-3.svg" alt="shape"></div>
        <div class="shape shape-a2"><img src="../../../public/assets/images/shape/shape-4.svg" alt="shape"></div>
        <div class="shape shape-a3"><img src="../../../public/assets/images/shape/shape-13.svg" alt="shape"></div>
        <div class="shape shape-a4"><img src="../../../public/assets/images/shape/shape-11.svg" alt="shape"></div>
    </section>
    <!--End CTA-->
</template>

<script>
export default { name: "Cta", };
</script>

<style></style>