<template>
    <!--Start Footer-->
    <footer>
        <div class="footer-row1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="email-subs">
                            <h3>Get New Insights Weekly</h3>
                            <p>News letter dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                                Enter your email</p>
                        </div>
                    </div>
                    <div class="col-lg-6 v-center">
                        <div class="email-subs-form">
                            <form>
                                <input type="email" placeholder="Email Your Address" name="emails">
                                <button type="submit" name="submit" class="lnk btn-main bg-btn">Subscribe <i
                                        class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-row2 py-30">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-4 col-sm-6  ftr-brand-pp">
                        <a class="navbar-brand mt30 mb25 f-dark-logo" href="#">
                            <img src="../../public/assets/images/logo-1.webp" alt="Logo" />
                        </a>
                        <a class="navbar-brand mt30 mb25 f-white-logo" href="#">
                            <img src="../../public/assets/images/logo-1.webp" alt="Logo" />
                        </a>
                        <p>Crafting innovative solutions for businesses of all sizes. We provide exceptional customer
                            service and support to ensure your success.</p>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <h5>Contact Us</h5>
                        <ul class="footer-address-list ftr-details">
                            <li>
                                <span><i class="fas fa-envelope"></i></span>
                                <p>Email <span> <a href="mailto:info@imobiledesigns.com">info@imobiledesigns.com</a></span>
                                </p>
                            </li>
                            <li>
                                <span><i class="fas fa-phone-alt"></i></span>
                                <p>Phone
                                    <a href="tel:+91 707-707-6330"><span>+91 707-707-6330</span></a>
                                    <a href="tel:+1 707-707-6330"><span>+1 707-707-6330</span></a>
                                </p>
                            </li>
                            <li>
                                <span><i class="fas fa-map-marker-alt"></i></span>
                                <p>Address <span> 203, Shree Kashi Parekh Complex, Near Swastik Cross Roads, Navarangpura,
                                        Ahmedabad - 380009.</span></p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <h5>Quick Links</h5>
                        <ul class="footer-address-list link-hover">
                            <li>
                                <RouterLink to="/" class="menu-links">Home</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/why-choose-imobile" class="menu-links">Why Choose Us</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/about-imobile" class="menu-links">About Us</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/careers" class="menu-links">Careers</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/contact-imobile" class="menu-links">Contact Us</RouterLink>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-sm-6">
                        <h5>Policies</h5>
                        <ul class="footer-address-list link-hover">
                            <li>
                                <RouterLink to="/payment" class="menu-links">Payment</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/refund-and-cancellation-policy">Refund Policy</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/customers-faq">Customer's FAQ</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/workplace-policy">Workplace Policy</RouterLink>
                            </li>
                            <li>
                                <RouterLink to="/terms-and-conditions">Terms and Conditions</RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-row3">
            <div class="copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="footer-social-media-icons">
                                <a v-for="social in socialMedia" :key="social.platform" :href="social.link" target="blank">
                                    <i :class="social.icon"></i></a>
                            </div>
                            <div class="footer-">
                                <p>© 2023-2024. All Rights Reserved By <a target="blank">iMobile Designs</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--End Footer-->
</template>

<script>
export default {
    data() {
        return {
            socialMedia: [
                { platform: 'Facebook', link: 'http://facebook.com/iMobileDesigns', icon: 'fab fa-facebook' },
                { platform: 'Twitter', link: 'https://twitter.com/imobiledesigns', icon: 'fab fa-twitter' },
                { platform: 'Instagram', link: 'https://www.instagram.com/imobiledesigns', icon: 'fab fa-instagram' },
                { platform: 'LinkedIn', link: 'https://www.linkedin.com/company/imobiledesigns', icon: 'fab fa-linkedin' },
                { platform: 'YouTube', link: 'https://www.youtube.com/@iMobileDesigns', icon: 'fab fa-youtube' },
            ],
        };
    },
    name: "Footer",
};
</script>

<style></style>