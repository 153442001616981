<template>
    <!--Start Why Choose-->
    <section class="service-block pad-tb bg-gradient7">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="common-heading ptag">
                        <span>Welcome to iMobile Designs </span>
                        <h2>Perks To Work With Us</h2>
                        <p class="mb30">Enjoy competitive benefits, professional growth opportunities, a collaborative
                            environment, and a commitment to work-life balance.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 mt30  wow fadeIn" v-for="(perk, index) in perks" :key="index"
                    :data-wow-delay="perk.delay">
                    <div class="s-block wide-sblock">
                        <div class="s-card-icon">
                            <img :src="perk.icon" :alt="perk.title" class="img-fluid"></div>
                        <div class="s-block-content">
                            <h4>{{ perk.title }}</h4>
                            <p>{{ perk.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Why Choose-->
</template>
 
<script>
export default { 
    data() {
    return {
      perks: [
        {
          icon: "assets/images/icons/teama.svg",
          title: "Reliable Service. In House Team",
          description: "Our team delivers consistent, high-quality service, ensuring reliability and seamless execution in every project.",
          delay: ".2s",
        },
        {
          icon: "assets/images/icons/link.svg",
          title: "Trusted by People Like You",
          description: "We foster trust through consistent, quality results and enduring client relationships, ensuring reliability at every step.",
          delay: ".5s",
        },
        {
          icon: "assets/images/icons/tech.svg",
          title: "Complete Technical Competency",
          description: "Provide comprehensive technical expertise, ensuring precise and effective solutions across all areas of our services.",
          delay: ".8s",
        },
        {
          icon: "assets/images/icons/hi.svg",
          title: "Friendly & Cordial in Nature",
          description: "We cultivate a warm, approachable environment that fosters positive interactions and strong team collaboration.",
          delay: "1.1s",
        },
        {
          icon: "assets/images/icons/badge.svg",
          title: "Excellent Quality Delivered on Time",
          description: "Consistently provide top-notch solutions with reliable punctuality, ensuring exceptional results within deadlines.",
          delay: "1.4s",
        },
        {
          icon: "assets/images/icons/tin.svg",
          title: "Effective & Continuous Communication",
          description: "We maintain clear, ongoing communication to ensure alignment and facilitate seamless project execution.",
          delay: "1.7s",
        },
      ],
    };
  },name: "WhyChoose", };
</script>
 
<style></style>