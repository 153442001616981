<template>
    <!--Start About-->
    <section class="about-agencys pad-tb block-1 dark-bg3">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-6">
                    <div class="common-heading text-l">
                        <h2 class="mb20">Career and Culture at iMobile</h2>
                        <p>At iMobile, we foster a dynamic and inclusive culture that promotes professional growth,
                            collaboration, and innovation. Our career development programs are designed to empower employees
                            through continuous learning, mentorship, and diverse opportunities for advancement.</p>
                        <p>We emphasize a culture of open communication, respect, and mutual support, creating an
                            environment where every individual can thrive and contribute meaningfully. By embracing
                            diversity and encouraging creativity, we cultivate a workplace that inspires excellence and
                            drives both personal and organizational success.</p>
                        <!-- <a href="#jobs" class="btn-main bg-btn2 lnk mt30"> View Opening <i
                                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a> -->
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="image-block mb0 m-mt30">
                        <img src="../../../public/assets/images/about/office-4.jpg" alt="about" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End About-->
</template>

<script>
export default { name: "About", };
</script>

<style></style>