import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'vanilla-tilt';
import 'jquery';
import 'magnific-popup';
import 'animate.css';
import 'wowjs';

// plugin css
import '../public/assets/css/bootstrap.min.css'
import '../public/assets/css/plugin.min.css'
// template style
import '../public/assets/css/style.css'
import '../public/assets/css/responsive.css'
import '../public/assets/css/darkmode.css'

const app = createApp(App);

app.use(router);    
app.mount('#app');