<template>
    <!--Start Testinomial-->
    <section class="testinomial-section bg-none pad-tb">
        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-lg-8">
                    <div class="common-heading">
                        <span>What our clients say about iMobile Designs.</span>
                        <h2>Over Satisfied Clients and Growing</h2>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-6">
                    <div class="video-testimonials owl-carousel">
                        <div class="video-review" data-tilt data-tilt-max="5" data-tilt-speed="1000">
                            <a class="video-link" href="https://www.youtube.com/watch?v=jb4QIAhrrSY">
                                <img src="../../../public/assets/images/client/client-pic.jpg" alt="client"
                                    class="img-fluid">
                                <div class="review-vid-details">
                                    <div class="-vid-ico"><span class="triangle-play2"></span></div>
                                    <p>Cina Cleaves</p>
                                </div>
                            </a>
                        </div>
                        <div class="video-review" data-tilt data-tilt-max="5" data-tilt-speed="1000">
                            <a class="video-link" href="https://www.youtube.com/watch?v=jb4QIAhrrSY">
                                <img src="../../../public/assets/images/client/client-pic-a.jpg" alt="client"
                                    class="img-fluid">
                                <div class="review-vid-details">
                                    <div class="-vid-ico"><span class="triangle-play2"></span></div>
                                    <p>Jokvch Marlin</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="owl-carousel testimonial-card-a pl25">
                        <div class="testimonial-card">
                            <div class="t-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div class="client-thumbs mt30">
                                <div class="media v-center">
                                    <div class="user-image bdr-radius"><img
                                            src="../../../public/assets/images/user-thumb/girl.jpg" alt="girl"
                                            class="img-fluid" /></div>
                                    <div class="media-body user-info v-center">
                                        <h5>Mike Smith</h5>
                                        <p>Business Owner, <small>Sydney, Australia</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial-card">
                            <div class="t-text">
                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div class="client-thumbs mt30">
                                <div class="media v-center">
                                    <div class="user-image bdr-radius"><img
                                            src="../../../public/assets/images/user-thumb/girl.jpg" alt="girl"
                                            class="img-fluid" /></div>
                                    <div class="media-body user-info">
                                        <h5>Fred Chener</h5>
                                        <p>CEO of Aramxx, <small>Ohio, US State</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial-card">
                            <div class="t-text">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                    has been the industry's standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                            <div class="client-thumbs mt30">
                                <div class="media v-center">
                                    <div class="user-image bdr-radius"><img
                                            src="../../../public/assets/images/user-thumb/girl.jpg" alt="girl"
                                            class="img-fluid" /></div>
                                    <div class="media-body user-info">
                                        <h5>Mathilda Burns</h5>
                                        <p>Backery Shop, <small>Tronto, Italy</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="review-ref mt100">
                        <div class="review-title-ref">
                            <h4>Read More Reviews</h4>
                            <p>Read our reviews from all over world.</p>
                        </div>
                        <div class="review-icons">
                            <a href="https://www.google.com/search?q=iMobile+Designs+(Immersive+Mobile+Designs+Pvt.+Ltd.)&sca_esv=f94f21a840b19fef&sca_upv=1&hl=en-IN&prmd=ivsn&sxsrf=ADLYWIKCZvBYjzts3lXIb3TViSoahRUfqw:1726481770449&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7mIc3i9jceCx59g4_GB5WnLJwAL1ZlNaljoQ9x1daeJd--EcvvtLNXTkZuX5NEleeTXNX1k%3D&sa=X&ved=2ahUKEwiz-I3lnceIAxWcV2wGHfqsA3MQ9qsLegQIKxAG&biw=834&bih=928&dpr=2"
                                target="_blank" class="wow fadeIn" data-wow-delay=".2s"><img
                                    src="../../../public/assets/images/about/reviews-icon-1.png" alt="review"
                                    class="img-fluid"></a>
                            <a href="https://clutch.co/profile/immersive-mobile-designs-private#highlights" target="_blank"
                                class="wow fadeIn" data-wow-delay=".4s"><img
                                    src="../../../public/assets/images/about/reviews-icon-2.png" alt="review"
                                    class="img-fluid"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Testinomial-->
</template>
 
<script>
export default {
    mounted() {
        this.initOwlCarousel();

        // this.initMagnificPopup();
    },
    methods: {
        initOwlCarousel() {
            $('.video-testimonials').owlCarousel({
                items: 2,
                nav: false,
                dots: false,
                autoplay: false,
                autoplayTimeout: 3500,
                smartSpeed: 1500,
                margin: 20,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    1024: {
                        items: 2
                    },
                    1400: {
                        items: 2
                    }
                }
            });

            $('.testimonial-card-a').owlCarousel({
                items: 1,
                loop: true,
                autoplay: true,
                autoplayTimeout: 6000,
                autoplayHoverPause: true,
                smartSpeed: 500,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    1024: {
                        items: 1
                    },
                    1400: {
                        items: 1
                    }
                }
            });
        // },
        // initMagnificPopup() {
        //     $('.video-link').magnificPopup({
        //         disableOn: 700,
        //         type: 'iframe',
        //         mainClass: 'mfp-fade',
        //         removalDelay: 160,
        //         preloader: false,
        //         fixedContentPos: false
        //     });
        }
    }, name: "Testinomial",
};
</script>
 
<style></style>