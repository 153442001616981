<template>
    <header class="nav-bg-b main-header navfix fixed-top menu-white">
        <Header></Header>
    </header>

    <Sidebar></Sidebar>

    <Hero></Hero>

    <About></About>

    <Service></Service>

    <Statistics></Statistics>

    <WhyChoose></WhyChoose>

    <!-- <Portfolio></Portfolio> -->

    <Clients></Clients>

    <WorkCategory></WorkCategory>

    <Testinomial></Testinomial>

    <Cta></Cta>

    <Location></Location>

    <Footer></Footer>

    <!-- lead generaton popup start -->
    <div class="modal leadpopup" id="leadModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="innerbody">
                        <div class="innerleft">
                            <div class="leadbtnclose"> <button type="button" class="btn-close"
                                    data-bs-dismiss="modal"></button></div>
                            <h3>Build Your App in just 2 Weeks</h3>
                            <p class="mt10">Leave your details and one of our experts will contact you soon</p>
                            <div class="form-block mt20">
                                <form action="#" id="quotes-form" method="post">
                                    <div class="fieldsets row">
                                        <div class="col-md-12 form-group floating-label">
                                            <input type="text" placeholder="" class="floating-input">
                                            <label>Full Name*</label>
                                        </div>
                                        <div class="col-md-12 form-group floating-label">
                                            <input type="email" placeholder="" class="floating-input">
                                            <label>Email Address*</label>
                                        </div>
                                        <div class="col-md-12 form-group floating-label">
                                            <input id="phone" type="tel" class="floating-input" placeholder="Phone Number*" style="padding-right: 70px;">
                                        </div>  
                                    </div>
                                    <div class="fieldsets mt20"> <button type="submit" name="submit"
                                            class="lnk btn-main bg-btn">Submit <i
                                                class="fas fa-chevron-right fa-icon"></i><span
                                                class="circle"></span></button> </div>
                                    <p class="trm"><i class="fas fa-lock"></i>We hate spam, and we respect your privacy.</p>
                                </form>
                            </div>
                        </div>
                        <div class="innerright popimg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- lead generaton popup end -->
</template>

<script>
import Header from '../views/Header.vue';
import Sidebar from '../views/Sidebar.vue';

import Hero from '../views/home/Hero.vue';
import About from '../views/home/About.vue';
import Service from '../views/home/Service.vue';
import Statistics from '../views/home/Statistics.vue';
import WhyChoose from '../views/home/WhyChoose.vue';
// import Portfolio from '../views/home/Portfolio.vue';
import Clients from '../views/home/Clients.vue';
import WorkCategory from '../views/home/WorkCategory.vue';
import Testinomial from '../views/home/Testinomial.vue';
import Cta from '../views/home/Cta.vue';
import Location from '../views/home/Location.vue';
import Footer from '../views/Footer.vue';

export default {
    components: { Header, Sidebar, Hero, About, Service, Statistics, WhyChoose, Clients, WorkCategory, Testinomial, Cta, Location, Footer },
    name: "Home",

    mounted() {
        const input = document.querySelector("#phone");
        const iti = window.intlTelInput(input, {
            initialCountry: "auto", // Automatically detect the user's country
            preferredCountries: ["us", "gb", "in"], // Set preferred countries
            geoIpLookup: function (callback) {
                fetch('https://ipinfo.io/json', { headers: { 'Accept': 'application/json' } })
                    .then((resp) => resp.json())
                    .then((resp) => callback(resp.country))
                    .catch(() => callback('us'));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // This loads the utility script for formatting
            allowDropdown: true,
            autoPlaceholder: "polite",
            separateDialCode: true, // Separates the dial code
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                // return "Mobile Number (" + selectedCountryData.dialCode + ")";
                return "";
            }
        });

        //     document.querySelector('form').addEventListener('submit', function(e) {
        //       e.preventDefault();
        //       const phoneNumber = iti.getNumber();
        //       console.log('Full phone number: ', phoneNumber);
        //   });
    }
};
</script>

<style scoped>
img {
    height: 60px;
}

.popimg {
    background-image: url('../../public/assets/images/service/mockup-app.jpg');
}
</style>