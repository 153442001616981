import { createRouter, createWebHistory } from "vue-router";
import Home from '../components/Home.vue';
import WhyChooseUs from '../components/WhyChooseUs.vue';
import AboutUs from '../components/AboutUs.vue';
import Careers from '../components/Careers.vue';
import ContactUs from '../components/ContactUs.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import RefundCancellation from '../components/RefundCancellation.vue';
import CustomersFAQ from '../components/CustomersFAQ.vue';
import WorkplacePolicy from '../components/WorkplacePolicy.vue';
import TermsandConditions from '../components/TermsandConditions.vue';
import Payment from '../components/Payment.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/why-choose-imobile', component: WhyChooseUs },
    { path: '/about-imobile', component: AboutUs },
    { path: '/careers', component: Careers },
    { path: '/contact-imobile', component: ContactUs },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/refund-and-cancellation-policy', component: RefundCancellation },
    { path: '/customers-faq', component: CustomersFAQ },
    { path: '/workplace-policy', component: WorkplacePolicy },
    { path: '/terms-and-conditions', component: TermsandConditions },
    { path: '/payment', component: Payment }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({behavior:'smooth'});
     }
});

export default router;