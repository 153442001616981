<template>
  <!--Workplace Policy-->
  <section class="why-choos-lg pad-tb">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="common-heading text-l left">
            <span>{{ policyTitle }}</span>
            <h6>{{ introduction.heading }}</h6>
            <p>{{ introduction.content }}</p><br>

            <div v-for="(section, index) in sections" :key="index">
              <h6>{{ section.title }}</h6><br>
              <div v-for="(subSection, subIndex) in section.content" :key="subIndex" class="tab-space">
                <h6>{{ subSection.subTitle }}</h6>
                <p>{{ subSection.subContent }}</p>

                <!-- Render nested subpoints, if any -->
                <ul v-if="subSection.subPoints">
                  <li v-for="(point, pointIndex) in subSection.subPoints" :key="pointIndex" class="tab-space">
                    {{ point }}
                  </li>
                </ul><br>
              </div><br>
            </div>

            <p>{{ note }}</p><br><br>
            <span>{{ conclusion.title }}</span>
            <p>{{ conclusion.content }}</p><br>

            <h6>Contact Information:</h6>
            <p><strong>Address:</strong> {{ contactInfo.address }}</p>
            <p><strong>Phone:</strong> {{ contactInfo.phone }}</p>
            <p><strong>Website:</strong> <a :href="contactInfo.website">{{ contactInfo.website }}</a></p>
            <p><strong>Email:</strong> <a :href="`mailto:${contactInfo.email}`">{{ contactInfo.email }}</a></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Workplace Policy-->
</template>
  
<script>
export default {
  data() {
    return {
      policyTitle: "Workplace Policy",
      introduction: {
        heading: "Introduction:",
        content:
          "Welcome to iMobile Designs, we are committed to fostering a productive, inclusive, and respectful work environment for all employees. This workplace policy outlines the guidelines, expectations, and benefits applicable to our employees. By joining our company, you need to abide by these policies."
      },
      sections: [
        {
          title: "1. Work Hours:",
          content: [
            {
              subTitle: "1.1. Standard Work Hours",
              subContent:
                "Our standard work hours are 9:00 AM to 6:00 PM from Monday to Friday."
            },
            {
              subTitle: "1.2. Flexibility",
              subContent:
                "We understand the need for work-life balance. We may offer flexible work arrangements, such as remote work options or flexible schedules, subject to managerial approval."
            }
          ]
        },
        {
          title: "2. Leave Policies:",
          content: [
            {
              subTitle: "2.1. Paid Leave",
              subContent:
                "Employees are given 15 days of paid leave per calendar year, which includes public holidays."
            },
            {
              subTitle: "2.2. Sick Leave",
              subContent:
                "Employees are eligible for paid sick leaves, subject to the submission of appropriate medical documentation."
            },
            {
              subTitle: "2.3. Other Leaves",
              subContent:
                "We provide additional leaves for bereavement, maternity/paternity, and other applicable situations, as per local laws and company policies."
            }
          ]
        },
        {
          title: "3. Code of Conduct:",
          content: [
            {
              subTitle: "3.1. Professionalism",
              subContent:
                "We expect all employees to maintain a high standard of professionalism, integrity, and ethical behavior."
            },
            {
              subTitle: "3.2. Respectful Workplace",
              subContent:
                "We foster a diverse and inclusive environment where all employees are treated with respect, irrespective of their gender, race, religion, sexual orientation, or other personal characteristics."
            },
            {
              subTitle: "3.3. Non-Discrimination and Harassment",
              subContent:
                "Discrimination, harassment, or any form of offensive behavior will not be tolerated. Employees are encouraged to report any incidents promptly, and a thorough investigation will be conducted."
            },
            {
              subTitle: "3.4. Confidentiality",
              subContent:
                "Employees must maintain the confidentiality of company and client information. Non-disclosure agreements may be required for specific projects or clients."
            }
          ]
        },
        {
          title: "4. Performance Evaluation:",
          content: [
            {
              subTitle: "4.1. Performance Reviews",
              subContent:
                "Regular performance evaluations will be conducted annually to assess employee performance, provide constructive feedback, and identify opportunities for growth and development."
            },
            {
              subTitle: "4.2. Career Development",
              subContent:
                "We encourage employees to enhance their skills and knowledge through training programs, certifications, conferences, and other professional development opportunities, as approved by management."
            }
          ]
        },
        {
          title: "5. Employee Benefits:",
          content: [
            {
              subTitle: "5.1. Health Insurance",
              subContent:
                "We provide comprehensive health insurance coverage to eligible employees and their dependents."
            },
            {
              subTitle: "5.2. Retirement Plans",
              subContent:
                "Eligible employees may participate in our retirement savings plan, subject to plan terms and conditions."
            },
            {
              subTitle: "5.3. Other Perks",
              subContent:
                "We may offer additional employee benefits such as gym memberships, transportation allowances, or recreational activities, as applicable."
            }
          ]
        },
        {
          title: "6. Data Security and Confidentiality:",
          content: [
            {
              subTitle: "6.1. Data Protection",
              subContent:
                "Employees are responsible for adhering to strict data security protocols, including secure handling of sensitive data and compliance with relevant privacy laws and regulations."
            },
            {
              subTitle: "6.2. Information Security",
              subContent:
                "Employees must follow company policies regarding the use of information technology systems, including password protection, data backups, and restricted access to confidential information."
            }
          ]
        },
        {
          title: "7. Grievance Procedures:",
          content: [
            {
              subTitle: "7.1. Open Communication",
              subContent:
                "We encourage employees to voice their concerns or grievances through open communication channels. Employees can approach their immediate supervisors, HR department, or use a designated grievance reporting mechanism."
            },
            {
              subTitle: "7.2. Confidentiality and Fairness",
              subContent:
                "All grievances will be handled promptly, confidentially, and impartially. Appropriate actions will be taken to address and resolve the issues to the best of our ability."
            }
          ]
        },
        {
          title: "8. Other Policies and agreements:",
          content: [
            {
              subTitle: "8.1. Bond Period:",
              subContent:
                "The employee must enter in 18 months of bond period with the company."
            },
            {
              subTitle: "8.2. Increment Policy:",
              subContent:
                "Employees who have completed one year in the company are eligible for increment which is based on their work and performance."
            }
          ]
        }
      ],
      note: "Please note that this workplace policy is subject to change at the discretion of the company. We expect employees to review and comply with updated policies as and when they are communicated.",
      conclusion: {
        title: "Conclusion",
        content:
          "By continuing your employment with iMobile Designs, you acknowledge your understanding and acceptance of the policies outlined above."
      },
      contactInfo: {
        address: "203, Shree Kashi Parekh Complex, Swastik Cross Roads, Navrangpura, Ahmedabad – 380009",
        phone: "+91 707-707-6330",
        website: "https://imobiledesigns.com",
        email: "info@imobiledesigns.com"
      }
    };
  },
  name: "WorkplacePolicy",
};
</script>
  
<style scoped>
.tab-space {
  margin-left: 2em;
}

.left span,
.left h6,
.left p,
.left li {
  text-align: left;
}
</style>