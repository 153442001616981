<template>
    <!--why choose-->
    <section class="why-choos-lg pad-tb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common-heading text-l">
                        <span>Why Choose Us</span>
                        <h2 class="mb20">Why The iMobile Designs Ranked Top Among The Leading Web & App Development
                            Companies</h2>
                        <p>We stand out for our innovative approach, skilled team, and consistent delivery of high-quality,
                            tailored solutions, all while prioritizing client satisfaction and seamless execution.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6" v-for="(column, index) in mediaColumns" :key="index">
                    <div class="itm-media-object mt40 tilt-3d">
                        <!-- Loop through each media item in the column -->
                        <div class="media" v-for="(item, idx) in column" :key="idx">
                            <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000">
                                <img :src="item.icon" :alt="item.alt" class="layer" :style="item.iconStyle" />
                            </div>
                            <div class="media-body">
                                <h4>{{ item.title }}</h4>
                                <p>{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End why choose-->
</template>
 
<script>
export default {
    data() {
        return {
            // Two-column structure, each containing a list of media objects
            mediaColumns: [
                [
                    {
                        icon: 'assets/images/icons/computers.svg',
                        alt: 'icon',
                        title: 'Streamlined Project Management',
                        description: 'We ensure efficient processes and clear communication throughout, leading to timely, successful project delivery and seamless execution.',
                    },
                    {
                        icon: 'assets/images/icons/worker.svg',
                        alt: 'icon',
                        title: 'A Dedicated Team of Experts',
                        description: 'Our passionate experts blend deep expertise with creativity to deliver exceptional, tailored solutions through flawless execution by our skilled developers, designers, and project managers.',
                    },
                    {
                        icon: 'assets/images/icons/Innovative.svg',
                        alt: 'icon',
                        title: 'Innovative Solutions',
                        description: 'We stay ahead of industry trends, utilizing cutting-edge technologies to create modern, functional, and visually appealing digital products.',
                        iconStyle: { height: '45px' },
                    },
                ],
                [
                    {
                        icon: 'assets/images/icons/Security.svg',
                        alt: 'icon',
                        title: 'Quality Assurance & Data Security',
                        description: 'We ensure top-quality solutions through rigorous testing and secure infrastructure, including data transmission and compliance with standards before the Beta Launch.',
                        iconStyle: { height: '45px' },
                    },
                    {
                        icon: 'assets/images/icons/Maintenance.svg',
                        alt: 'icon',
                        title: 'Ongoing Support and Maintenance',
                        description: 'We provide comprehensive support and maintenance services to keep your product running smoothly. We get you updates, enhancements, or technical assistance at any point of time.',
                        iconStyle: { height: '45px' },
                    },
                    {
                        icon: 'assets/images/icons/deal.svg',
                        alt: 'icon',
                        title: 'Completion of Project in Given Time',
                        description: 'We ensure projects are completed on time through efficient processes and a dedicated team, consistently meeting deadlines without compromising quality.',
                    },
                ],
            ],
        };
    }, name: "WhyChoose",
};
</script>
 
<style></style>