<template>
    <!--Start Location-->
    <section class="our-office pad-tb">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="common-heading">
                        <span>Our Locations</span>
                        <h2>Our Office</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center upset shape-numm">
                <div class="col-lg-4 col-sm-6 shape-loc wow fadeInUp" v-for="(section, index) in office.sections"
                    :key="index" :data-wow-delay="section.delay">
                    <div class="office-card">
                        <div class="skyline-img" data-tilt data-tilt-max="4" data-tilt-speed="1000">
                            <img :src="section.imageSrc" :alt="section.location" class="img-fluid" />
                        </div>
                        <div class="office-text">
                            <h4>{{ section.country }}</h4>
                            <p>{{ section.address }}</p>
                            <a :href="section.mapLink" target="blank" class="btn-outline rount-btn"><i
                                    class="fas fa-map-marker-alt"></i></a>
                            <a :href="'tel:' + section.phone" target="blank" v-if="section.phone"
                                class="btn-outline rount-btn"><i class="fas fa-phone-alt"></i></a>
                            <a :href="'mailto:' + section.email" target="blank" class="btn-outline rount-btn"><i
                                    class="fas fa-envelope"></i></a>
                            <a :href="'skype:' + section.skype + '?chat'" target="blank" class="btn-outline rount-btn"><i
                                    class="fab fa-skype"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Location-->
</template>

<script>
export default {
    data() {
        return {
            office: {
                sections: [
                    {
                        country: "United States",
                        address: "2700 E Lanark St, Ste 120, Meridian, USA - 83642.",
                        phone: "+1 707-707-6330",
                        email: "info@imobiledesigns.com",
                        skype: "sunil.jagnani",
                        mapLink: "http://bit.ly/3Zo7ORq",
                        imageSrc: 'assets/images/location/usa.svg',
                        location: "United States",
                        delay: ".2s"
                    },
                    {
                        country: "United Kingdom",
                        address: "21 Carr Mills, Buslingthorpe Lane, Leeds, West Yorkshire, LS7 2DD",
                        email: "info@imobiledesigns.com",
                        skype: "sunil.jagnani",
                        mapLink: "https://bit.ly/3Zkv1nJ",
                        imageSrc: 'assets/images/location/uk.svg',
                        location: "United Kingdom",
                        delay: ".5s"
                    },
                    {
                        country: "India",
                        address: "203, Shree Kashi Parekh Complex, Near Swastik Cross Roads, Navarangpura, Ahmedabad - 380009.",
                        phone: "+91 707-707-6330",
                        email: "info@imobiledesigns.com",
                        skype: "sunil.jagnani",
                        mapLink: "https://bit.ly/3ZoHKpu",
                        imageSrc: 'assets/images/location/india.svg',
                        location: "India",
                        delay: ".8s"
                    }
                ]
            }
        };
    },
    name: "Location",
};
</script>

<style scoped>
.skyline-img {
    background: #fbf4f1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    -webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059), 0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071), 0 25px 40px -30px rgba(0, 0, 0, 0.2);
}

.skyline-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>