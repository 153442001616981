<template>
    <!--Refund & Cancellation Policy-->
    <section class="why-choos-lg pad-tb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common-heading text-l left">
                        <!-- Privacy Policy Title -->
                        <span>{{ RefundCancellation.title }}</span>

                        <!-- Introduction -->
                        <p>{{ RefundCancellation.introduction }}</p><br>

                        <!-- Sections -->
                        <div v-for="(section, index) in RefundCancellation.sections" :key="index">
                            <h6>{{ section.heading }}</h6>
                            <p>{{ section.content }}</p><br>
                        </div><br>

                        <!-- Contact Information -->
                        <span>{{ RefundCancellation.contact.title }}</span>
                        <p>{{ RefundCancellation.contact.content }}
                            <RouterLink to="/contact-imobile"> {{ RefundCancellation.contact.link }}</RouterLink>
                        </p><br>

                        <p><strong>Address:</strong> {{ RefundCancellation.contact.address }}</p>
                        <p><strong>Phone:</strong> {{ RefundCancellation.contact.phone }}</p>
                        <p><strong>Email:</strong> <a :href="`mailto:${RefundCancellation.contact.email}`">{{
                            RefundCancellation.contact.email }}</a></p>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--Refund & Cancellation Policy-->
</template>
 
<script>
export default {
    data() {
        return {
            RefundCancellation: {
                title: "Refund & Cancellation Policy",
                introduction:
                    "At Immersive Mobile Designs, we take pride in providing high-quality service tailored to our clients’ unique needs. Due to the nature of our business, we have the following refund and cancellation terms:",
            
                    sections: [
                    {
                        heading: "No Refund Policy",
                        content:
                            "As a service-based company, once hours have been spent on a project and preparation has been made, we are unable to offer any refunds under any circumstances. All payments made for services, including project initiation, development, design, or consulting, are non-refundable. This includes partial or full payments for ongoing or completed work."
                    },
                    {
                        heading: "Cancellation Policy",
                        content:
                            "Before Project Commencement: If you choose to cancel a project before any work has started, a full refund of any advance payments may be issued. Once work begins, no refunds will be processed. After Project Commencement: If you wish to cancel a project after work has begun, no refunds will be provided. However, you will not be billed for work beyond what has been completed."
                    },
                    {
                        heading: "Change Requests",
                        content:
                            "If changes to the project scope are requested after work has begun, they may require additional charges depending on the nature and complexity of the request."
                    },
                    {
                        heading: "Project Delays",
                        content:
                            "We are committed to delivering projects on time as per the agreed schedule. However, delays caused due to lack of communication from the client’s side, incomplete requirements, or external factors beyond our control will not qualify for any refund or compensation."
                    }
                ],
                contact: {
                    title: "Contact Us",
                    content: "If you have any questions or concerns regarding our refund and cancellation policy, please contact us.",
                    link: "contact us.",
                    address: "203, Shree Kashi Parekh Complex, Swastik Cross Roads, Navrangpura, Ahmedabad – 380009.",
                    phone: "+91 707-707-6330 | +1 707-707-6330",
                    email: "info@imobiledesigns.com"
                }
            }
        };
    },
    name: "RefundCancellation",
};
</script>
 
<style scoped>
.left span,
.left h6,
.left p {
    text-align: left;
}
</style>