<template>
    <header class="nav-bg-w main-header navfix fixed-top menu-white">
        <Header></Header>
    </header>

    <Sidebar></Sidebar>

    <!--Breadcrumb Area-->
    <section class="breadcrumb-area banner-2">
        <div class="text-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 v-center">
                        <div class="bread-inner">
                            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
                                <ul>
                                    <li><RouterLink to="/" class="menu-links">Home</RouterLink></li>
                                    <li>Terms & Conditions</li>
                                </ul>
                            </div>
                            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
                                <h2>Terms & Conditions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Breadcrumb-->

    <TermsandConditions></TermsandConditions>

    <Footer></Footer>
</template>

<script>

import Header from '../views/Header.vue';
import Sidebar from '../views/Sidebar.vue';
import About from '../views/whychooseus/About.vue';
import TermsandConditions from '../views/termsandconditions/termsandconditions.vue';
import Footer from '../views/Footer.vue';

export default {
    components: { Header, Sidebar, About, TermsandConditions, Footer },
    name: "Chooseus",
};
</script>

<style></style>