export function initializeDarkMode() {
  // On load, check if dark mode is enabled in localStorage and apply it
  const darkModeStatus = localStorage.getItem("darkModeStatus");
  if (darkModeStatus === "true") {
    document.body.classList.add("active-dark");
    const chk = document.getElementById("niwax");
    if (chk) {
      chk.checked = true;
    }
  }
}

export function toggleDarkMode() {
  const chk = document.getElementById("niwax");
  if (chk.checked) {
    document.body.classList.add("active-dark");
    localStorage.setItem("darkModeStatus", "true");
  } else {
    document.body.classList.remove("active-dark");
    localStorage.setItem("darkModeStatus", "false");
  }
}