<template>
    <!--Start Customer's FAQ-->
    <section class="pad-tb">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" v-for="(item, index) in accordionItems" :key="index">
                            <h2 class="accordion-header" :id="'heading-' + index">
                                <button class="accordion-button" :class="{ collapsed: !item.isOpen }" type="button"
                                    data-bs-toggle="collapse" :data-bs-target="'#collapse-' + index"
                                    :aria-expanded="item.isOpen ? 'true' : 'false'" :aria-controls="'collapse-' + index"
                                    @click="toggleAccordion(index)">
                                    {{ item.title }}
                                </button>
                            </h2>
                            <div :id="'collapse-' + index" class="accordion-collapse collapse"
                                :class="{ show: item.isOpen }" :aria-labelledby="'heading-' + index"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="data-reqs">
                                        <!-- Use v-html only for the 6th item (index === 5) -->
                                        <div v-if="index === 5">
                                            <p v-for="(line, idx) in item.content" :key="idx" v-html="line"></p>
                                        </div>
                                        <!-- For other items, show content as normal text -->
                                        <div v-else>
                                            <p>{{ item.content }}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Customer's FAQ-->
</template>
 
<script>
export default {
    data() {
        return {
            accordionItems: [
                {
                    title: "How can Immersive Mobile Designs help grow my business with custom app and web development?",
                    content:
                        "At Immersive Mobile Designs, we specialize in creating custom mobile apps and responsive websites tailored to your business goals. From enhancing customer engagement to automating processes, we leverage cutting-edge technologies to deliver solutions that boost your brand's online presence and help your business grow.",
                    isOpen: true // First item is open by default
                },
                {
                    title: "Why should I choose Immersive Mobile Designs for app and web development services?",
                    content:
                        "We don’t just build apps and websites; we craft tailored digital solutions that focus on delivering an exceptional user experience. Our team of expert developers ensures that your app or website is not only functional but also optimized for performance, SEO, and scalability — helping your business stand out in a competitive market.",
                    isOpen: false
                },
                {
                    title: "Can you redesign or upgrade my existing website or mobile app?",
                    content:
                        "Yes! Whether you need to redesign your website for a modern look or upgrade your app with the latest features, we specialize in website modernization and app upgrades that enhance functionality, performance, and user experience, ensuring your digital presence stays relevant.",
                    isOpen: false
                },
                {
                    title: "How quickly can you deliver a custom website or mobile app?",
                    content:
                        "Our project timelines depend on your specific needs. However, we can deliver a custom website in 1-3 months and a mobile app in 3-6 months. If you need a faster turnaround, we can speed up the process by assigning additional resources to your project to meet tight deadlines without compromising on quality.",
                    isOpen: false
                },
                {
                    title: "What are your pricing options for mobile app and web development services?",
                    content:
                        "We offer flexible, competitive pricing that caters to businesses of all sizes. Our developer rates range from $20 to $30 per hour, based on the project complexity and expertise required. You can also choose from hourly, weekly, or monthly packages. Contact us for a tailored quote based on your specific needs.",
                    isOpen: false
                },
                {
                    title: "What’s your process for app and web development?",
                    content: [
                        "Our development process is customer-focused and results-driven. We start with a free consultation to understand your business goals, followed by:",
                        "- Strategy and planning",
                        "- Design and prototyping",
                        "- Development (using Agile/Scrum methodologies)",
                        "- Quality assurance testing",
                        "- Deployment and post-launch support",
                        "You’ll be involved at every step, ensuring transparency and a final product that aligns with your business vision."
                    ],
                    isOpen: false
                },
                {
                    title: "Do you offer ongoing maintenance and support for apps and websites?",
                    content:
                        "Yes, we provide post-launch support and maintenance services to keep your website or app running smoothly. Our services include bug fixes, software updates, security enhancements, and performance optimization, ensuring long-term success for your digital platforms.",
                    isOpen: false
                },
                {
                    title: "How do you ensure security for apps and websites?",
                    content:
                        "We implement best practices for app and website security. This includes data encryption, secure payment gateway integration, regular security audits, and compliance with industry standards like GDPR, HIPAA, and PCI-DSS to protect your users’ sensitive information and ensure the safety of your digital assets.",
                    isOpen: false
                },
                {
                    title: "Can you integrate AI or machine learning into my app or website?",
                    content:
                        "Yes, we specialize in AI and machine learning integration. Whether you want to automate tasks, provide personalized user experiences, or analyze customer behavior, we can incorporate intelligent features to enhance your app or website's capabilities and help your business thrive in the digital age.",
                    isOpen: false
                },
                {
                    title: "How do I get started with Immersive Mobile Designs?",
                    content:
                        "Getting started is easy! Schedule a free 15-30 minute consultation where we’ll discuss your project requirements, timeline, and budget. We’ll then provide a custom solution tailored to your business needs, ensuring seamless communication and collaboration throughout the project.",
                    isOpen: false
                },
                {
                    title: "What industries does Immersive Mobile Designs serve?",
                    content:
                        "We work with clients across diverse industries, including retail, healthcare, education, finance, real estate, and more. Whether you need an e-commerce platform, a healthcare app, or an educational tool, we create custom solutions that align with your industry standards and customer expectations.",
                    isOpen: false
                }
            ]
        };
    },
    methods: {
        toggleAccordion(index) {
            // Toggle the clicked accordion item
            this.accordionItems[index].isOpen = !this.accordionItems[index].isOpen;
            // Ensure only one is open at a time (accordion behavior)
            this.accordionItems.forEach((item, i) => {
                if (i !== index) {
                    item.isOpen = false;
                }
            });
        }
    },
    name: "CustomersFAQ",
};
</script>
 
<style></style>