<template>
    <!--Start sidebar -->
    <div class="niwaxofcanvas offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample">
        <div class="offcanvas-body">
            <div class="cbtn animation">
                <div class="btnclose"> <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button></div>
            </div>
            <div class="form-block sidebarform">
                <h4>Request A Quote</h4>
                <form @submit.prevent="handleSubmit" data-bs-toggle="validator" class="shake mt20">
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <input v-model="form.name" type="text" id="name" placeholder="Enter name" required
                                data-error="Please fill Out">
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group col-sm-12">
                            <input v-model="form.email" type="email" id="email" placeholder="Enter email" required>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12 mb-3">
                            <input v-model="form.mobile" type="tel" id="mobile" placeholder="Enter mobile" required
                                data-error="Please fill Out" style="padding-right: 100px;">
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group col-sm-12">
                            <select v-model="form.Dtype" id="Dtype" required class="form-control" name="Dtype">
                                <option value="">Select Requirement</option>
                                <option value="webapp">Web App</option>
                                <option value="mobileapp">Mobile App</option>
                                <option value="both">Both</option>
                                <option value="other">Other</option>
                            </select>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea v-model="form.message" id="message" rows="5" placeholder="Enter your message"
                            required></textarea>
                        <div class="help-block with-errors"></div>
                    </div>
                    <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn">Submit <span
                            class="circle"></span></button>
                    <div v-if="formSubmitted" class="h3 text-center">Form submitted successfully!</div>
                    <div class="clearfix"></div>
                </form>
            </div>
            <div class="getintouchblock mt30">
                <h4>Get In Touch</h4>
                <p class="mt10">Please fill out the form below if you have a plan or project in mind that you'd like to
                    share with us.</p>
                <div class="media mt15">
                    <div class="icondive"><img src="../../public/assets/images/icons/call.svg" alt="icon"></div>
                    <div class="media-body getintouchinfo">
                        <a href="tel:+91 707-707-6330">+91 707-707-6330 <span>Mon-Fri 9am - 6pm</span></a><br>
                        <a href="tel:+1 707-707-6330">+1 707-707-6330 <span>24/7 and in real-time</span></a>
                    </div>
                </div>
                <div class="media mt15">
                    <div class="icondive"><img src="../../public/assets/images/icons/whatsapp.svg" alt="icon"></div>
                    <div class="media-body getintouchinfo">
                        <a href="https://wa.me/917077076330?" target="_blank">+91 707-707-6330 <span>Mon-Fri 9am -
                                6pm</span></a>
                        <a href="https://wa.me/17077076330?" target="_blank">+1 707-707-6330 <span>24/7 and in
                                real-time</span></a>
                    </div>
                </div>
                <div class="media mt15">
                    <div class="icondive"><img src="../../public/assets/images/icons/mail.svg" alt="icon"></div>
                    <div class="media-body getintouchinfo">
                        <a href="mailto:info@imobiledesigns.com">info@imobiledesigns.com <span>Online Support</span></a>
                    </div>
                </div>
                <div class="media mt15">
                    <div class="icondive"><img src="../../public/assets/images/icons/map.svg" alt="icon"></div>
                    <div class="media-body getintouchinfo">
                        <a href="https://maps.app.goo.gl/B11DgcvTGmSSALsZ9">203, Shree Kashi Parekh Complex, Near Swastik
                            Cross Roads,
                            Navarangpura, Ahmedabad - 380009.<span>Visit Our Office</span></a>
                    </div>
                </div>
            </div>
            <div class="contact-data mt30">
                <h4>Follow Us On:</h4>
                <div class="social-media-linkz mt10">
                    <a v-for="social in socialMedia" :key="social.platform" :href="social.link" target="blank">
                        <i :class="social.icon"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!--end sidebar -->
</template>

<script>
export default {
    name: "Sidebar",
    data() {
        return {
            form: {
                name: '',
                email: '',
                mobile: '',
                Dtype: '',
                message: '',
            },
            formSubmitted: false,
            socialMedia: [
                { platform: 'Facebook', link: 'http://facebook.com/iMobileDesigns', icon: 'fab fa-facebook' },
                { platform: 'Twitter', link: 'https://twitter.com/imobiledesigns', icon: 'fab fa-twitter' },
                { platform: 'Instagram', link: 'https://www.instagram.com/imobiledesigns', icon: 'fab fa-instagram' },
                { platform: 'LinkedIn', link: 'https://www.linkedin.com/company/imobiledesigns', icon: 'fab fa-linkedin' },
                { platform: 'YouTube', link: 'https://www.youtube.com/@iMobileDesigns', icon: 'fab fa-youtube' },
            ],
        };
    },
    methods: {
        handleSubmit() {
            if (this.form.name && this.form.email && this.form.mobile && this.form.Dtype && this.form.message) {
                this.formSubmitted = true;
                console.log('Form Data:', this.form);
                // You can add your submission logic here, e.g., sending data to a server
            }
        },
    },
    mounted() {
        const input = document.querySelector("#mobile");
        const iti = window.intlTelInput(input, {
            initialCountry: "auto",// Automatically detect the user's country
            preferredCountries: ["us", "gb", "in"], // Set preferred countries
            geoIpLookup: function (callback) {
                fetch('https://ipinfo.io/json', { headers: { 'Accept': 'application/json' } })
                    .then((resp) => resp.json())
                    .then((resp) => callback(resp.country))
                    .catch(() => callback('us'));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // This loads the utility script for formatting
            allowDropdown: true,
            autoPlaceholder: "polite",
            separateDialCode: true, // Separates the dial code
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                // return "Mobile Number (" + selectedCountryData.dialCode + ")";
                return "";
            }
        });
        //     document.querySelector('form').addEventListener('submit', function(e) {
        //       e.preventDefault();
        //       const phoneNumber = iti.getNumber();
        //       console.log('Full phone number: ', phoneNumber);
        //   });
    }
};
</script>

<style></style>