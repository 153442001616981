<template>
    <header class="nav-bg-w main-header navfix fixed-top menu-white">
        <Header></Header>
    </header>

    <Sidebar></Sidebar>

    <!--Breadcrumb Area-->
    <section class="breadcrumb-area banner-1 img">
        <div class=" text-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 v-center">
                        <div class="bread-inner">
                            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
                                <ul>
                                    <li>
                                        <RouterLink to="/" class="menu-links">Home</RouterLink>
                                    </li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
                                <h2>About Company</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Breadcrumb Area-->

    <About></About>

    <WhyChoose></WhyChoose>

    <Footer></Footer>
</template>

<script>
import Header from '../views/Header.vue';
import Sidebar from '../views/Sidebar.vue';

import About from '../views/aboutus/About.vue';
import WhyChoose from '../views/aboutus/WhyChoose.vue';
import Footer from '../views/Footer.vue';

export default {
    components: { Header, Sidebar, About, WhyChoose, Footer },
    name: "Aboutus",
};
</script>

<style scoped>
.img {
    background-image: url('../../public/assets/images/banner/9.jpg');
    ;
}
</style>