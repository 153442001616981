<template>
    <!--why choose-->
    <section class="why-choos-lg pad-tb deep-dark">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="common-heading text-l">
                        <span>Why Choose Us</span>
                        <h2 class="mb20">Why The iMobile Designs <span class="text-second text-bold">Ranked Top</span> Among
                            The Leading Web & App Development Companies</h2>
                        <p>We stand out for our innovative approach, skilled team, and consistent delivery of high-quality,
                            tailored solutions, all while prioritizing client satisfaction and seamless execution.</p>
                        <div class="itm-media-object mt40 tilt-3d">
                            <div v-for="(item, index) in mediaItems" :key="index" class="media mt40">
                                <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000">
                                    <img :src="item.icon" :alt="item.alt" class="layer" />
                                </div>
                                <div class="media-body">
                                    <h4>{{ item.title }}</h4>
                                    <p>{{ item.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn"
                        data-wow-duration="2s"><img src="../../../public/assets/images/about/about-company.jpg" alt="image"
                            class="img-fluid"></div>
                    <p class="text-center mt30">Built on trust, innovation, and a commitment to excellence, making us the
                        go-to choice for companies looking to turn their vision into reality since 2013.</p>
                    <div class="cta-card mt60 text-center">
                        <h3 class="mb20">Let's Start a <span class="text-second text-bold">New Project</span> Together</h3>
                        <p>Partner with us to transform your vision into reality, leveraging our expertise and creativity
                            for exceptional results.</p>
                        <RouterLink to="/contact-imobile" class="niwax-btn1 mt30"><span>Request A Quote <i
                                    class="fas fa-chevron-right fa-icon"></i></span></RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End why choose-->
</template>
 
<script>
export default {
    data() {
        return {
            mediaItems: [
                {
                    icon: "assets/images/icons/computers.svg",
                    alt: "Streamlined Project Management",
                    title: "Streamlined Project Management",
                    description:
                        "We ensure efficient processes and clear communication throughout, leading to timely, successful project delivery and seamless execution.",
                },
                {
                    icon: "assets/images/icons/worker.svg",
                    alt: "A Dedicated Team of Experts",
                    title: "A Dedicated Team of Experts",
                    description:
                        "Our passionate experts blend deep expertise with creativity to deliver exceptional, tailored solutions through flawless execution by our skilled developers, designers, and project managers.",
                },
                {
                    icon: "assets/images/icons/deal.svg",
                    alt: "Completion of Project in Given Time",
                    title: "Completion of Project in Given Time",
                    description:
                        "We ensure projects are completed on time through efficient processes and a dedicated team, consistently meeting deadlines without compromising quality.",
                },
            ],
        };
    }, name: "WhyChoose",
};
</script>
 
<style></style>