<template>
    <!--Start About-->
    <section class="about-sec-rpb pad-tb">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-10">
                    <div class="common-heading">
                        <span>We Are Creative Agency</span>
                        <h1 class="mb30"><span class="text-second">High-Quality</span> Web And Mobile App Development
                            Company
                        </h1>
                        <p>
                            <span class="text-radius text-light text-animation bg-b">iMobile Designs</span>is a dynamic IT
                            company with 13 years of experience, specializing in delivering innovative digital solutions
                            tailored to clients' needs. Over the years, we’ve honed our expertise in web development, mobile
                            applications, UI/UX design, Game Development, and digital marketing.
                        </p>
                        <h3 class="mt30 mb30">Big Ideas, creative people, new technology.</h3>
                        <p>Our seasoned team has successfully executed a wide range of projects, from startups to large
                            enterprises, helping businesses transform their online presence with cutting-edge technology and
                            creative strategies. Our long-standing experience enables us to blend creativity with
                            technology, ensuring impactful results that drive growth and digital innovation. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End About-->
</template>

<script>
export default { name: "About", };
</script>

<style></style>