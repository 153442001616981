<template>
    <!--Start statistics-->
    <div class="statistics-section bg-gradient6 pad-tb tilt3d">
        <div class="container">
            <div class="row justify-content-center t-ctr">
                <div class="col-lg-4 col-sm-6" v-for="(stat, index) in statsMain" :key="index">
                    <div class="statistics">
                        <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                            <img :src="stat.icon" :alt="stat.alt" class="img-fluid" />
                        </div>
                        <div class="statnumb">
                            <span class="counter">{{ stat.value }}</span><span v-if="stat.plus">+</span>
                            <p>{{ stat.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row small t-ctr">
                <div class="col-lg-3 col-sm-6" v-for="(stat, index) in statsSmall" :key="index">
                    <div class="statistics">
                        <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                            <img :src="stat.icon" :alt="stat.alt" class="img-fluid" />
                        </div>
                        <div class="statnumb">
                            <span class="counter">{{ stat.value }}</span><span v-if="stat.k">k</span>
                            <p>{{ stat.text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End statistics-->
</template>
 
<script>
export default {
    data() {
        return {
            statsMain: [
                {
                    icon: "assets/images/icons/startup.svg",
                    value: "13",
                    plus: true,
                    text: "Year In Business",
                    alt: "years",
                },
                {
                    icon: "assets/images/icons/team.svg",
                    value: "15",
                    plus: true,
                    text: "Team Members",
                    alt: "team",
                },
            ],
            statsSmall: [
                {
                    icon: "assets/images/icons/deal.svg",
                    value: "350",
                    text: "Happy Clients",
                    alt: "happy",
                },
                {
                    icon: "assets/images/icons/computers.svg",
                    value: "1.5",
                    k: true,
                    text: "Projects Done",
                    alt: "project",
                },
                {
                    icon: "assets/images/icons/worker.svg",
                    value: "200",
                    k: true,
                    text: "Hours Worked",
                    alt: "work",
                },
                {
                    icon: "assets/images/icons/customer-service.svg",
                    value: "24/7",
                    text: "Support Available",
                    alt: "support",
                },
            ],
        };
    }, name: "Statistics",
};
</script>
 
<style></style>