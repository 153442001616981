<template>
    <!--Start Enquire Form-->
    <section class="contact-page pad-tb">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 v-center">
                    <div class="common-heading text-l">
                        <span>Contact Now</span>
                        <h2 class="mt0 mb0">Have Question? Write a Message</h2>
                        <p class="mb60 mt20">We will catch you as early as we receive the message</p>
                    </div>
                    <div class="form-block">
                        <form id="contactForm" data-bs-toggle="validator" class="shake">
                            <div class="row">
                                <div class="form-group col-sm-6">
                                    <input type="text" id="name" placeholder="Enter name" required
                                        data-error="Please fill Out">
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <input type="email" id="email" placeholder="Enter email" required>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-sm-6">
                                    <input type="tel" id="phone" placeholder="Enter mobile" required
                                        data-error="Please fill Out" style="padding-right: 70px;">
                                    <div class="help-block with-errors"></div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <select name="Dtype" id="Dtype" required>
                                        <option value="">Select Requirement</option>
                                        <option value="webapp">Web App</option>
                                        <option value="mobileapp">Mobile App</option>
                                        <option value="both">Both</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <textarea id="message" rows="5" placeholder="Enter your message" required></textarea>
                                <div class="help-block with-errors"></div>
                            </div>
                            <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn">Submit <span
                                    class="circle"></span></button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 v-center">
                    <div class="contact-details">
                        <div class="contact-card wow fadeIn" data-wow-delay=".2s">
                            <div class="info-card v-center">
                                <span><i class="fas fa-phone-alt"></i> Phone:</span>
                                <div class="info-body">
                                    <p>Our customer service team is available 24/7 hours.</p>
                                    <a href="tel:+91 707-707-6330">+91 707-707-6330 | </a>
                                    <a href="tel:+1 707-707-6330">+1 707-707-6330</a>
                                </div>
                            </div>
                        </div>
                        <div class="email-card mt30 wow fadeIn" data-wow-delay=".5s">
                            <div class="info-card v-center">
                                <span><i class="fas fa-envelope"></i> Email:</span>
                                <div class="info-body">
                                    <p>Our support team will get back to in 24 hours during standard business hours.</p>
                                    <a href="mailto:info@imobiledesigns.com">info@imobiledesigns.com</a>
                                </div>
                            </div>
                        </div>
                        <div class="skype-card mt30 wow fadeIn" data-wow-delay=".9s">
                            <div class="info-card v-center">
                                <span><i class="fab fa-skype"></i> Skype:</span>
                                <div class="info-body">
                                    <p>We Are Online 24/7 hours.</p>
                                    <a href="skype:sunil.jagnani?chat">Sunil Jagnani</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Enquire Form-->
</template>
 
<script>
export default {
    name: "Enquire",
    mounted() {
        const input = document.querySelector("#phone");
        const iti = window.intlTelInput(input, {
            initialCountry: "auto",// Automatically detect the user's country
            preferredCountries: ["us", "gb", "in"], // Set preferred countries
            geoIpLookup: function (callback) {
                fetch('https://ipinfo.io/json', { headers: { 'Accept': 'application/json' } })
                    .then((resp) => resp.json())
                    .then((resp) => callback(resp.country))
                    .catch(() => callback('us'));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // This loads the utility script for formatting
            allowDropdown: true,
            autoPlaceholder: "polite",
            separateDialCode: true, // Separates the dial code
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                // return "Mobile Number (" + selectedCountryData.dialCode + ")";
                return "";
            }
        });
        //     document.querySelector('form').addEventListener('submit', function(e) {
        //       e.preventDefault();
        //       const phoneNumber = iti.getNumber();
        //       console.log('Full phone number: ', phoneNumber);
        //   });
    }
};
</script>
 
<style></style>