<template>
    <!--Start Clients-->
    <section class="clients-section- bg-gradient15 pad-tb">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="common-heading">
                        <span>Our happy customers</span>
                        <h2 class="mb30">Some of our Clients</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="clients-logos text-center col-12 ">
                        <ul class="row text-center clearfix cliflex">
                            <li v-for="(client, index) in clients" :key="index"
                                :class="['col-lg-2', 'col-md-3', 'col-sm-4', 'col-6', 'wow', 'fadeIn']"
                                :data-wow-delay="client.delay">
                                <div class="brand-logo">
                                    <img :src="client.logo" :alt="client.name" class="img-fluid">
                                </div>
                                <p>{{ client.name }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Clients-->
</template>
 
<script>
export default {
    data() {
        return {
            clients: [
                { logo: "assets/images/client/BRICKSRUS.svg", name: "Bricksrus", delay: ".2s" },
                { logo: "assets/images/client/ET.svg", name: "Eluminous Technologies", delay: ".4s" },
                { logo: "assets/images/client/EXPRESSKITCHENS.svg", name: "Express Kitchens", delay: ".6s" },
                { logo: "assets/images/client/FUNDRAISERS.svg", name: "Fundraisers Ltd.", delay: ".8s" },
                { logo: "assets/images/client/VELRIDERS.svg", name: "Velriders", delay: "1s" },
                { logo: "assets/images/client/VIVABACK.svg", name: "VivaBack", delay: "1.2s" },
                { logo: "assets/images/client/RQ.svg", name: "Recruit Genie", delay: "1.4s" },
                { logo: "assets/images/client/BOOSTAPP.svg", name: "Boost App Social", delay: "1.6s" },
                { logo: "assets/images/client/VERIPOINT.svg", name: "Veripoint", delay: "1.8s" },
                { logo: "assets/images/client/RKKF.svg", name: "RKKF", delay: "2s" },
                { logo: "assets/images/client/YOGA.svg", name: "Gotta Yoga", delay: "2.2s" },
            ],
        };
    }, name: "Clients",
};
</script>
 
<style scoped>
.brand-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cliflex {
    display: flex;
    justify-content: center;
}
</style>