<template>
    <!--Privacy Policy-->
    <section class="why-choos-lg pad-tb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common-heading text-l left">
                        <!-- Privacy Policy Title -->
                        <span>{{ privacyPolicy.title }}</span>

                        <!-- Introduction -->
                        <p>{{ privacyPolicy.introduction }}</p><br>

                        <!-- Sections -->
                        <div v-for="(section, index) in privacyPolicy.sections" :key="index">
                            <h6>{{ section.heading }}</h6>
                            <p>{{ section.content }}</p><br>
                        </div><br>

                        <!-- Contact Information -->
                        <span>{{ privacyPolicy.contact.title }}</span>
                        <p>{{ privacyPolicy.contact.content }}
                            <RouterLink to="/contact-imobile"> {{ privacyPolicy.contact.link }}</RouterLink>
                        </p><br>

                        <p><strong>Address:</strong> {{ privacyPolicy.contact.address }}</p>
                        <p><strong>Phone:</strong> {{ privacyPolicy.contact.phone }}</p>
                        <p><strong>Email:</strong> <a :href="`mailto:${privacyPolicy.contact.email}`">{{
                            privacyPolicy.contact.email }}</a></p>
                        <br>

                        <!-- Conclusion -->
                        <p>{{ privacyPolicy.conclusion }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Privacy Policy-->
</template>
 
<script>
export default {
    data() {
        return {
            privacyPolicy: {
                title: "Privacy Policy",
                introduction:
                    "At iMobile Designs, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website or use our services.",
                sections: [
                    {
                        heading: "Information Collection",
                        content:
                            "We may collect personal information from you when you voluntarily provide it to us, such as when you fill out a contact form or request more information about our services. This information may include your name, email address, contact number, and other relevant details."
                    },
                    {
                        heading: "Use of Personal Information",
                        content:
                            "We use the personal information you provide to respond to your inquiries, fulfil your requests, and provide you with the information or services you have requested. We may also use your information to improve our services, customize your experience on our website, and communicate with you about our latest updates and offerings."
                    },
                    {
                        heading: "Information Sharing",
                        content:
                            "We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third-party service providers who assist us in operating our website or conducting our business, as long as they agree to keep your information confidential."
                    },
                    {
                        heading: "Data Security",
                        content:
                            "We take appropriate measures to protect the security of your personal information and ensure it is not accessed, disclosed, altered, or destroyed. We use industry-standard security techniques, including encryption, to safeguard your data. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security."
                    },
                    {
                        heading: "Use of Cookies",
                        content:
                            "Our website may use cookies to enhance your browsing experience. Cookies are small files that are placed on your device’s hard drive to collect information about your activities on our website. You can choose to disable cookies through your browser settings, although this may affect your ability to access certain features or functionality of our website."
                    },
                    {
                        heading: "External Links",
                        content:
                            "Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party sites you visit."
                    },
                    {
                        heading: "Children’s Privacy",
                        content:
                            "Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children. If you believe we may have inadvertently collected personal information from a child, please contact us so that we can remove the information from our records."
                    },
                    {
                        heading: "Changes to the Privacy Policy",
                        content:
                            "We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates or changes."
                    }
                ],
                contact: {
                    title: "Contact Us",
                    content: "If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please ",
                    link: "contact us.",
                    address: "203, Shree Kashi Parekh Complex, Swastik Cross Roads, Navrangpura, Ahmedabad – 380009.",
                    phone: "+91 707-707-6330 | +1 707-707-6330",
                    email: "info@imobiledesigns.com"
                },
                conclusion:
                    "By using our website or providing your personal information, you signify your acceptance of this Privacy Policy."
            }
        };
    },
    name: "PrivacyPolicy",
};
</script>
 
<style scoped>
.left span,
.left h6,
.left p {
    text-align: left;
}
</style>