<template>
    <!--Start Service-->
    <section class="service-section-prb pad-tb">
        <div class="container">
            <div class="row upset">
                <div v-for="(service, index) in services" :key="index"
                    :class="['col-lg-' + service.col + '-cus', 'wow', 'fadeInUp', { 'mt30-': service.hasMargin }]"
                    :data-wow-delay="service.delay" data-tilt data-tilt-max="5" data-tilt-speed="1000">

                    <div :class="['service-sec-list', 'srvc-bg-nx', 'srcl' + (index + 1)]">

                        <img :src="service.icon" :alt="service.title" />
                        <h5 class="mb10">{{ service.title }}</h5>
                        <ul class="-service-list">
                            <li v-for="(item, i) in service.items" :key="i"><a>{{ item }}</a></li>
                        </ul>
                        <p>{{ service.description }}</p>
                    </div>
                </div>
                <div class="-cta-btn mt70">
                    <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                        <p>Hire a <span>Dedicated Developer</span></p>
                        <RouterLink to="/payment" class="btn-main bg-btn2 lnk">Hire Now<i
                                class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Service-->
</template>
<script>
export default {
    data() {
        return {
            services: [
                {
                    icon: "assets/images/icons/ai.svg",
                    title: "AI / ML Technologies",
                    items: [
                        "Python",
                        "TensorFlow",
                        "PyTorch",
                        "Keras",
                        "OpenAI",
                        "LLM",
                        "Natural Language Processing (NLP)",
                        "Open CV",
                        "Generative AI",
                    ],
                    description: "Leverage AI and Machine Learning for data analysis and automation.",
                    delay: ".2s",
                    col: 6,
                    hasMargin: false,
                },
                {
                    icon: "assets/images/icons/webdevelopment.svg",
                    title: "Web Development",
                    items: ["HTML", "CSS", "React JS", "Next JS", "Vue JS", "Angular"],
                    description: "Create dynamic and responsive websites with cutting-edge technologies and frameworks.",
                    delay: ".4s",
                    col: 3,
                    hasMargin: false,
                },
                {
                    icon: "assets/images/icons/mobile-app.svg",
                    title: "Mobile App Development",
                    items: ["IOS", "Flutter", "Android", "React Native", "Swift", "Kotlin", "Java"],
                    description: "Build innovative and user-friendly mobile apps for iOS and Android.",
                    delay: ".6s",
                    col: 3,
                    hasMargin: false,
                },
                {
                    icon: "assets/images/icons/uiux.svg",
                    title: "UI / UX Design",
                    items: ["Figma", "Adobe XD", "Sketch", "Photoshop", "Illustrator"],
                    description: "Craft engaging and intuitive user experiences with stunning interface design.",
                    delay: ".8s",
                    col: 3,
                    hasMargin: true,
                },
                {
                    icon: "assets/images/icons/database.svg",
                    title: "Database Technologies",
                    items: ["MySQL", "PostgreSQL", "MongoDB", "AWS", "Firebase", "Sqlite", "Microsoft SQL Server", "Mariadb"],
                    description: "Manage data storage, retrieval, and organization, ensuring efficient, scalable, and secure handling of information critical to application performance.",
                    delay: "1.2s",
                    col: 6,
                    hasMargin: true,
                },
                {
                    icon: "assets/images/icons/backend.svg",
                    title: "Backend Technologies",
                    items: ["PHP", "Laravel", "Node.JS", "Express JS", "Python"],
                    description: "Handling databases, APIs, and ensuring efficient, secure data processing for seamless application functionality.",
                    delay: "1s",
                    col: 3,
                    hasMargin: true,
                },
                {
                    icon: "assets/images/icons/cloud.svg",
                    title: "Cloud Services",
                    items: ["Azure", "GCP", "AWS"],
                    description: "Scalable, secure, and cost-effective solutions that boost flexibility, efficiency, and reliability.",
                    delay: ".8s",
                    col: 3,
                    hasMargin: true,
                },
                {
                    icon: "assets/images/icons/staff.svg",
                    title: "Staff Augmentation",
                    items: ["IT Staff Aug", "Marketing Staff", "Software Development", "Technical Support" ],
                    description: "Provides flexible, skilled resources to seamlessly enhance your team's success.",
                    delay: "1s",
                    col: 3,
                    hasMargin: true,
                },
                {
                    icon: "assets/images/icons/seo.svg",
                    title: "SEO Service",
                    items: [
                        "Keyword Research",
                        "On-Page SEO",
                        "Off-Page SEO",
                        "Technical SEO",
                        "Content SEO",
                        "SEO Audits",
                        "Local SEO",
                    ],
                    description: "Our SEO services boost online visibility, driving targeted traffic and higher rankings for your project.",
                    delay: "1.2s",
                    col: 6,
                    hasMargin: true,
                },
            ],
        };
    }, name: "Service",
};
</script>

<style></style>