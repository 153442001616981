<template>
    <!--Start work-category-->
    <section class="work-category pad-tb">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 v-center">
                    <div class="common-heading text-l">
                        <span>Industries we work for</span>
                        <h2>Helping Businesses in All Domains</h2>
                        <p>We help businesses across all industries by providing tailored IT solutions that drive growth and
                            innovation.</p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="work-card-set">
                        <div v-for="(card, index) in workCards" :key="index" class="icon-set wow fadeIn"
                            :data-wow-delay="card.delay">
                            <div :class="['work-card', 'cd' + (index + 1)]">
                                <div class="icon-bg">
                                    <img :src="card.icon" :alt="card.alt" />
                                </div>
                                <p>{{ card.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End work-category-->
</template>
 
<script>
export default {
    data() {
        return {
            workCards: [
                { icon: 'assets/images/icons/icon-1.png', name: 'Social Networking', alt: 'Industries', delay: '.2s' },
                { icon: 'assets/images/icons/icon-2.png', name: 'Digital Marketing', alt: 'Industries', delay: '.4s' },
                { icon: 'assets/images/icons/icon-3.png', name: 'Ecommerce Development', alt: 'Industries', delay: '.6s' },
                { icon: 'assets/images/icons/icon-4.png', name: 'Photo & Video Service', alt: 'Industries', delay: '.8s' },
                { icon: 'assets/images/icons/icon-5.png', name: 'Banking Service', alt: 'Industries', delay: '1s' },
                { icon: 'assets/images/icons/icon-6.png', name: 'Enterprise Service', alt: 'Industries', delay: '1.2s' },
                { icon: 'assets/images/icons/icon-7.png', name: 'Education Service', alt: 'Industries', delay: '1.4s' },
                { icon: 'assets/images/icons/icon-8.png', name: 'Tour and Travels', alt: 'Industries', delay: '1.6s' },
                { icon: 'assets/images/icons/icon-9.png', name: 'Health Service', alt: 'Industries', delay: '1.8s' },
                { icon: 'assets/images/icons/icon-10.png', name: 'Event & Ticket', alt: 'Industries', delay: '2s' },
                { icon: 'assets/images/icons/icon-11.png', name: 'Restaurant Service', alt: 'Industries', delay: '2.2s' },
                { icon: 'assets/images/icons/icon-12.png', name: 'Business Consultant', alt: 'Industries', delay: '2.4s' },
            ],
        };
    }, name: "WorkCategory",
};
</script>
 
<style></style>