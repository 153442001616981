<template>
    <header class="nav-bg-w main-header navfix fixed-top menu-white">
        <Header></Header>
    </header>

    <Sidebar></Sidebar>

    <!--Breadcrumb Area-->
    <section class="breadcrumb-area banner-6">
        <div class="text-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 v-center">
                        <div class="bread-inner">
                            <div class="bread-menu wow fadeInUp" data-wow-delay=".2s">
                                <ul>
                                    <li><RouterLink to="/" class="menu-links">Home</RouterLink></li>
                                    <li>Career</li>
                                </ul>
                            </div>
                            <div class="bread-title wow fadeInUp" data-wow-delay=".5s">
                                <h2>Job Openings</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Breadcrumb Area-->

    <About></About>

    <WhyChoose></WhyChoose>

    <!-- <EnquireForm></EnquireForm> -->

    <div class="popup-modals">
        <div class="modal" id="modalform">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="common-heading">
                            <h4 class="mt0 mb0">Apply Now</h4>
                        </div>
                        <button type="button" class="closes" data-bs-dismiss="modal">&times;</button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body pt40 pb60">
                        <div class="form-block fdgn2">
                            <form id="contact-form" method="post" action="#">
                                <div class="fieldsets row">
                                    <div class="col-md-6 form-group"><input id="form_name" type="text" name="name"
                                            placeholder="Enter your name *" required="required"> </div>
                                    <div class="col-md-6 form-group"><input id="form_email" type="email" name="email"
                                            placeholder="Enter your email *" required="required"> </div>
                                </div>
                                <div class="fieldsets row">
                                    <div class="col-md-6 form-group"><input id="phone" type="tel" name="phone"
                                            placeholder="Enter your Phone No *" required="required"> </div>
                                    <div class="col-md-6 form-group">
                                        <select id="form_need" name="need" required="required">
                                            <option value="">Select Post</option>
                                            <option value="Graphic Design">Graphic Design</option>
                                            <option value="Web Design">Web Design</option>
                                            <option value="App Design">App Design</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="fieldsets form-group"> <textarea id="form_message" name="message"
                                        placeholder="Message *" rows="4" required="required"></textarea> </div>
                                <div class="fieldsets- row">
                                    <div class="col-md-12 form-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input mb0" id="customFile">
                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                        <p><small>Please upload maximum 5 files Only pdf, docx and doc files.</small></p>
                                    </div>
                                </div>
                                <div class="fieldsets mt20">
                                    <button type="submit" class="lnk btn-main bg-btn">Submit Application<span
                                            class="circle"></span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>

<script>
import Header from '../views/Header.vue';
import Sidebar from '../views/Sidebar.vue';

import About from '../views/careers/About.vue';
import WhyChoose from '../views/careers/WhyChoose.vue';
// import EnquireForm from '../views/careers/EnquireForm.vue';

import Footer from '../views/Footer.vue';

export default {
    // components: { Header, Sidebar, About, WhyChoose, EnquireForm, Footer },
    components: { Header, Sidebar, About, WhyChoose, Footer },
    name: "Careers",
    mounted() {
        const input = document.querySelector("#phone");
        const iti = window.intlTelInput(input, {
            initialCountry: "auto", // Automatically detect the user's country
            preferredCountries: ["us", "gb", "in"], // Set preferred countries
            geoIpLookup: function (callback) {
                fetch('https://ipinfo.io/json', { headers: { 'Accept': 'application/json' } })
                    .then((resp) => resp.json())
                    .then((resp) => callback(resp.country))
                    .catch(() => callback('us'));
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js", // This loads the utility script for formatting
            allowDropdown: true,
            autoPlaceholder: "polite",
            separateDialCode: true, // Separates the dial code
            customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
                // return "Mobile Number (" + selectedCountryData.dialCode + ")";
                return "";
            }
        });

        //     document.querySelector('form').addEventListener('submit', function(e) {
        //       e.preventDefault();
        //       const phoneNumber = iti.getNumber();
        //       console.log('Full phone number: ', phoneNumber);
        //   });
    }
};

// Add the following code if you want the name of the file appear on select
// $(".custom-file-input").on("change", function () {
//     var fileName = $(this).val().split("\\").pop();
//     $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
// });
</script>

<style></style>