<template>
    <!--Start Hero-->
    <section class="hero-card-web bg-gradient12 shape-bg3" id="home">
        <div class="hero-main-rp container-fluid">
            <div class="row">
                <div class="col-lg-5">
                    <div class="hero-heading-sec">
                        <h2 class="wow fadeIn" data-wow-delay="0.3s"><span>Web.</span> <span>Mobile.</span>
                            <span>Graphic.</span> <span>Marketing.</span>
                        </h2>
                        <p class="wow fadeIn" data-wow-delay="0.6s">Website and App development solution for transforming
                            and innovating businesses.</p>
                        <RouterLink to="/contact-imobile" class="niwax-btn2 wow fadeIn" data-wow-delay="0.8s">Contact Us <i
                                class="fas fa-chevron-right fa-ani"></i></RouterLink>
                        <div class="awards-block-tt  wow fadeIn" data-wow-delay="1s">
                            <img src="../../../public/assets/images/hero/awards-logo.png" alt="awards-logo"
                                class="img-fluid" style="height: 100px; margin-top: -20px;" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="hero-content-sec wow fadeIn" data-wow-delay="0.8s">
                        <div class="video-intro-pp">
                            <a class="video-link play-video" href="https://www.youtube.com/watch?v=jb4QIAhrrSY">
                                <span class="triangle-play"></span>
                            </a>
                        </div>
                        <div class="title-hero-oth">
                            <p>We design digital solutions <span>for brands and companies</span></p>
                        </div>
                    </div>

                    <div class="hero-right-scmm">
                        <div class="hero-service-cards wow fadeInRight" data-wow-duration="2s">
                            <div class="owl-carousel service-card-prb">
                                <div class="service-slide card-bg-a" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport" src="../../../public/assets/images/service/ai.svg">
                                            </div>
                                            <div class="title-serv-c"><span>AI</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-b" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport"
                                                    src="../../../public/assets/images/service/mobile.svg">
                                            </div>
                                            <div class="title-serv-c"><span>Mobile</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-c" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport" src="../../../public/assets/images/service/web.svg">
                                            </div>
                                            <div class="title-serv-c"><span>Web</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-d" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport"
                                                    src="../../../public/assets/images/service/e-commerce.svg">
                                            </div>
                                            <div class="title-serv-c"><span>e-Commerce</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-e" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport"
                                                    src="../../../public/assets/images/service/car-rental.svg">
                                            </div>
                                            <div class="title-serv-c"><span>Car Rental</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-f" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport"
                                                    src="../../../public/assets/images/service/startup.svg">
                                            </div>
                                            <div class="title-serv-c"><span>Startup</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-g" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport" src="../../../public/assets/images/service/app.svg">
                                            </div>
                                            <div class="title-serv-c"><span>Custom App</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                                <div class="service-slide card-bg-h" data-tilt data-tilt-max="5" data-tilt-speed="1000"><a
                                        href="#">
                                        <div class="service-card-hh">
                                            <div class="image-sr-mm">
                                                <img alt="custom-sport"
                                                    src="../../../public/assets/images/service/virtual-reality.svg">
                                            </div>
                                            <div class="title-serv-c"><span>AR</span> Solution</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--End Hero-->
</template>

<script>
export default {
    mounted() {
        this.initOwlCarousel();

        this.initMagnificPopup();
    },
    methods: {
        initOwlCarousel() {
            $('.service-card-prb').owlCarousel({
                items: 7,
                loop: true,
                autoplay: true,
                margin: 20,
                nav: false,
                dots: false,
                autoplayTimeout: 3500,
                autoplayHoverPause: true,
                smartSpeed: 2000,
                responsive: {
                    0: {
                        items: 1
                    },
                    520: {
                        items: 2
                    },
                    768: {
                        items: 3
                    },
                    1200: {
                        items: 3
                    },
                    1400: {
                        items: 3
                    },
                    1600: {
                        items: 3
                    },
                    2000: {
                        items: 4
                    },
                    2200: {
                        items: 4
                    },
                }
            });
        },
        initMagnificPopup() {
            $('.video-link').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });
        }
    }, name: "Hero",
};
</script>

<style></style>